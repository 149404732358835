export default [
  {
    path: "/users",
    name: "users",
    meta: {
      title: "List User",
    },
    component: () => import("@/views/feui/user/List.vue"),
  },
  {
    path: "/users/add",
    name: "users-add",
    meta: {
      title: "Tambah User",
      action: "store",
    },
    component: () => import("@/views/feui/user/Form.vue"),
  },
  // {
  //     path: '/users/:id/show',
  //     name: 'users-show',
  //     component: () => import('@/views/halal-food/users/UsersView.vue'),
  // },
  {
    path: "/users/:id",
    name: "users-edit",
    meta: {
      title: "Ubah Data User",
      action: "edit",
    },
    component: () => import("@/views/feui/user/Form.vue"),
  },

  {
    path: "/roles",
    name: "roles",
    meta: {
      title: "List Role",
    },
    component: () => import("@/views/feui/role/List.vue"),
  },
  {
    path: "/roles/add",
    name: "roles-add",
    meta: {
      title: "Tambah Role",
      action: "store",
    },
    component: () => import("@/views/feui/role/Form.vue"),
  },
  // {
  //     path: '/users/:id/show',
  //     name: 'users-show',
  //     component: () => import('@/views/halal-food/users/UsersView.vue'),
  // },
  {
    path: "/roles/:id",
    name: "roles-edit",
    meta: {
      title: "Ubah Data role",
      action: "edit",
    },
    component: () => import("@/views/feui/role/Form.vue"),
  },

  {
    path: "/partners",
    name: "partners",
    meta: {
      title: "List Partner",
    },
    component: () => import("@/views/feui/supported/List.vue"),
  },
  {
    path: "/partners/add",
    name: "partners-add",
    meta: {
      title: "Tambah Partner",
      action: "store",
    },
    component: () => import("@/views/feui/supported/Form.vue"),
  },
  // {
  //     path: '/users/:id/show',
  //     name: 'users-show',
  //     component: () => import('@/views/halal-food/users/UsersView.vue'),
  // },
  {
    path: "/partners/:id",
    name: "partners-edit",
    meta: {
      title: "Ubah Data Partner",
      action: "edit",
    },
    component: () => import("@/views/feui/supported/Form.vue"),
  },

  {
    path: "/category_indicators",
    name: "category_indicators",
    meta: {
      title: "List Category Indicator",
    },
    component: () => import("@/views/feui/category_indicator/List.vue"),
  },
  {
    path: "/category_indicators/add",
    name: "category_indicators-add",
    meta: {
      title: "Tambah Category Indicator",
      action: "store",
    },
    component: () => import("@/views/feui/category_indicator/Form.vue"),
  },
  // {
  //     path: '/users/:id/show',
  //     name: 'users-show',
  //     component: () => import('@/views/halal-food/users/UsersView.vue'),
  // },
  {
    path: "/category_indicators/:id",
    name: "category_indicators-edit",
    meta: {
      title: "Ubah Data Category Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/category_indicator/Form.vue"),
  },

  {
    path: "/indicators",
    name: "indicators",
    meta: {
      title: "List Indicator",
    },
    component: () => import("@/views/feui/indicator/List.vue"),
  },
  {
    path: "/indicators/add",
    name: "indicators-add",
    meta: {
      title: "Tambah Indicator",
      action: "store",
    },
    component: () => import("@/views/feui/indicator/Form.vue"),
  },
  // {
  //     path: '/users/:id/show',
  //     name: 'users-show',
  //     component: () => import('@/views/halal-food/users/UsersView.vue'),
  // },
  {
    path: "/indicators/:id",
    name: "indicators-edit",
    meta: {
      title: "Ubah Data Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/Form.vue"),
  },
  {
    path: "/indicators/:id/legend",
    name: "indicators-legend",
    meta: {
      title: "Legend Data Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/Legend.vue"),
  },

  {
    path: "/indicators/:id/event",
    name: "indicators-event",
    meta: {
      title: "Event Data Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/Event.vue"),
  },

  {
    path: "/indicators/:id/highlight",
    name: "indicators-highlight",
    meta: {
      title: "Highlight Data Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/Highlight.vue"),
  },

  {
    path: "/indicators/:id",
    name: "indicators-edit",
    meta: {
      title: "Ubah Data Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/Form.vue"),
  },

  {
    path: "/indicators/:id/event/add",
    name: "indicators-event-add",
    meta: {
      title: "Tambah Data Event Indicator",
      action: "store",
    },
    component: () => import("@/views/feui/indicator/EventForm.vue"),
  },

  {
    path: "/indicators/:id/event/:id_event/edit",
    name: "indicators-event-edit",
    meta: {
      title: "Ubah Data Event Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/EventForm.vue"),
  },

  {
    path: "/indicators/:id/highlight/add",
    name: "indicators-highlight-add",
    meta: {
      title: "Tambah Data Highlight Indicator",
      action: "store",
    },
    component: () => import("@/views/feui/indicator/HighlightForm.vue"),
  },

  {
    path: "/indicators/:id/highlight/:id_highlight/edit",
    name: "indicators-highlight-edit",
    meta: {
      title: "Ubah Data Highlight Indicator",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator/HighlightForm.vue"),
  },

  {
    path: "/provinces",
    name: "provinces",
    meta: {
      title: "List Provinsi",
    },
    component: () => import("@/views/feui/province/List.vue"),
  },

  {
    path: "/districts",
    name: "districts",
    meta: {
      title: "List District",
    },
    component: () => import("@/views/feui/district/List.vue"),
  },

  {
    path: "/indicator_datas",
    name: "indicator_datas",
    meta: {
      title: "List Indicator Data",
    },
    component: () => import("@/views/feui/indicator_data/List.vue"),
  },
  {
    path: "/indicator_datas/add",
    name: "indicator_datas-add",
    meta: {
      title: "Tambah Indicator Data",
      action: "store",
    },
    component: () => import("@/views/feui/indicator_data/Form.vue"),
  },
  {
    path: "/indicator_datas/:id/show",
    name: "indicator-show",
    meta: {
      title: "Detail Indicator Data",
    },
    component: () => import("@/views/feui/indicator_data/Show.vue"),
  },
  {
    path: "/indicator_datas/:id",
    name: "indicator_datas-edit",
    meta: {
      title: "Ubah Indicator Data",
      action: "edit",
    },
    component: () => import("@/views/feui/indicator_data/Form.vue"),
  },

  {
    path: "/faqs",
    name: "faqs",
    meta: {
      title: "List FAQ",
    },
    component: () => import("@/views/feui/faq/List.vue"),
  },
  {
    path: "/faqs/add",
    name: "faqs-add",
    meta: {
      title: "Tambah FAQ",
      action: "store",
    },
    component: () => import("@/views/feui/faq/Form.vue"),
  },
  {
    path: "/faqs/:id",
    name: "faqs-edit",
    meta: {
      title: "Ubah Data FAQ",
      action: "edit",
    },
    component: () => import("@/views/feui/faq/Form.vue"),
  },

  {
    path: "/static-pages",
    name: "static-pages",
    meta: {
      title: "List About Us",
    },
    component: () => import("@/views/feui/static-page/List.vue"),
  },
  {
    path: "/static-pages/add",
    name: "static-pages-add",
    meta: {
      title: "Tambah About Us",
      action: "store",
    },
    component: () => import("@/views/feui/static-page/Form.vue"),
  },
  {
    path: "/static-pages/:id",
    name: "static-pages-edit",
    meta: {
      title: "Ubah Data About Us",
      action: "edit",
    },
    component: () => import("@/views/feui/static-page/Form.vue"),
  },
];
